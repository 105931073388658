


























































































































.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .newsListNav {
      height: 8rem;
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
      .maskLayer {
        position: absolute;
        background-color: #000843;
        opacity: 0.36;
        width: 100%;
        height: 100%;
        left: 0;
      }
      .ListNav {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .itemWrapper {
          display: flex;
          justify-content: center;
          align-items: stretch;
          div {
            margin: 0 4rem;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            color: rgb(176, 194, 238);
            cursor: pointer;
            &:hover {
              color: #ffffff;
            }
          }
          .active {
            border-bottom: 0.33rem solid #fbde00;
            color: #fff;
          }
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}
