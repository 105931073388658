.news-page .news-bar {
  height: auto;
  position: relative;
  margin-bottom: 2%;
}
.news-page .news-bar .bannerWrapper {
  width: 100%;
}
.news-page .news-bar .bannerWrapper img {
  width: 100%;
  height: 100%;
}
.news-page .news-bar .newsListNav {
  height: 8rem;
  position: absolute;
  z-index: 0;
  bottom: 0;
  width: 100%;
}
.news-page .news-bar .newsListNav .maskLayer {
  position: absolute;
  background-color: #000843;
  opacity: 0.36;
  width: 100%;
  height: 100%;
  left: 0;
}
.news-page .news-bar .newsListNav .ListNav {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.news-page .news-bar .newsListNav .ListNav .itemWrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.news-page .news-bar .newsListNav .ListNav .itemWrapper div {
  margin: 0 4rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  color: #b0c2ee;
  cursor: pointer;
}
.news-page .news-bar .newsListNav .ListNav .itemWrapper div:hover {
  color: #ffffff;
}
.news-page .news-bar .newsListNav .ListNav .itemWrapper .active {
  border-bottom: 0.33rem solid #fbde00;
  color: #fff;
}
.news-page .fade-enter-active,
.news-page .fade-leave-active {
  transition: opacity 0.5s;
}
.news-page .fade-enter,
.news-page .fade-leave-to {
  opacity: 0;
}
.news-page .pagenav {
  margin-top: 2.71%;
  margin-bottom: 4.67%;
  text-align: center;
}
.news-page .pagenav .pagination li .active {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
.news-page .pagenav .pagination li a {
  margin-right: 0.83rem;
}
.news-page .pagenav .pagination li a:hover {
  border-color: #2b8dfa;
  background-color: #ffffff;
}
.news-page .pagenav .pagination li a:focus {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
